export default [
    {
      english: {
        title: "Select payment method",
        pay: 'pay',
        InAppPay: 'In App Pay',
        QRCode: 'QR Code',
        kpayWarning: `<strong>KBZ in app pay</strong> is not available at laptop!!`,
        qrinfo: 'After you place the order, QR code will be available to be scanned.',
        ayainfo: "Enter Your Phone Number, Receive AYA Pay Notifications , Effortless Checkouts Made Possible with AYA Pay",
        kbzinfo: "You don't need to provide any information for this payment and click the button below!!",
        phPlaceholder: 'Phone Number',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Eamil',
        city: 'City',
        address: 'Address',
        paywith: `Pay With`,
        merchantName: 'Merchant name',
        InvoiceId: 'Invoice ID',
        mmk: 'MMK',
        paymentTimeout: 'Payment Timeout',
        completePayment: 'Complete Payment',
        remainingTime: 'Remaining Time',
        paymentSuccessful: 'Payment Successful',
        paymentSuccessfulone: 'The payment is completed sucessfully',
        backtoHome: 'Back to Home'
      },
  
      myanmar: {
        title: "ပေးချေမည့်နည်းလမ်းကို ရွေးချယ်ပါ",
        pay: 'ဖြင့်ပေးချေမည်',
        InAppPay: 'ဖုန်း App',
        QRCode: 'QR ကုဒ်',
        kpayWarning: `<strong>KBZ ဖုန်းနှင့်ပေးချေမှု</strong> သည် Laptop တွင်အသုံးမပြုနိုင်ပါ။`,
        qrinfo: 'အတည်ပြုပြီးပါက scan လုပ်ရန် QR ကုဒ်ပေါ်လာပါမည်။',
        ayainfo: "သင့်၏ဖုန်းနံပါတ်ကိုရိုက်ထည့်ပြီး AYA Pay သတိပေးချက်ကို ရယူခြင်းဖြင့် ထိရောက်သော ငွေပေးချေမှုကို ပြုလုပ်ပါ။",
        kbzinfo: "ဤငွေပေးချေမှု နည်းလမ်းတွင်မည်သည့်အချက်အလက်မှတောင်းမည်မဟုတ်ပါ။ အောက်တွင်ရှိတော့ အတည်ပြုမည်ကို နှိပ်ပါ။",
        phPlaceholder: 'ဖုန်းနံပါတ်',
        firstName: 'ရှေ့နာမည်',
        lastName: 'နောက်နာမည်',
        email: 'အီးမေးလိပ်စာ',
        city: 'မြို့နာမည်',
        address: 'နေရပ်လိပ်စာ',
        paywith: `ဖြင့်ပေးချေမည်`,
        merchantName: 'လက်ခံသူအမည်:',
        InvoiceId: 'လုပ်ဆောင်သည်အမှတ်:',
        mmk: 'ကျပ်',
        paymentTimeout: 'ဤ QR သည် ကန့်သက်ချိန်ကျော်လွန်သွားပါပြီ။',
        completePayment: 'ငွေပေးချေမှုကို ဆက်လက်လုပ်ဆောင်ပါ',
        remainingTime: 'လက်ကျန်အချိန်',
        paymentSuccessful: 'ငွေပေးချေမှုအောင်မြင်ပါသည်။',
        paymentSuccessfulone: 'ငွေပေးချေမှုကို အောင်မြင်စွာ လုပ်ဆောင်ပြီးဖြစ်ပါသည်။',
        backtoHome: 'ရှေ့သို့ပြန်သွားမည်'




      },
    },
  ];