import logo from './logo.png';
import './App.css';
import React, {useEffect, useRef, useState} from 'react'
import jwtDecode from 'jwt-decode';
import {  Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Nav, NavItem, NavLink, TabContent, TabPane, Alert} from 'reactstrap'
import { AlertCircle, RefreshCcw, Smartphone } from 'react-feather'
import { io } from "socket.io-client";
import QRCode from 'react-qr-code';
import QR from './QR.png';
import { BrowserView, MobileView } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';

import Flags from 'react-flags-select';
// import 'react-flags-select/css/react-flags-select.css';

import datas from "./data";

function Main() {
  const [decodedData, setDecodedData] = useState({})
  const [token, setToken] = useState()
  const [ranges, setRanges] = useState()
  const [types, setType] = useState([])
  const [selectedPaymentList, setSelectedPaymentList] = useState()
  const [phone, setPhone] = useState()

  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [successMsg, setSuccessMsg] = useState()
  const [success, setSuccess] = useState(false)
  const [clickComfirm, setClickComfirm] = useState(false)

  const [qrData, setQrData] = useState()
  const [timeout, setTimeOut] = useState()
  const [remainingTime, setRemainingTime] = useState('');

  const [curPaymentType, setCurPaymentType] = useState('E-Wallet');
  const [curPaymentList, setCurpaymentList] = useState()
  const [curSelectedPaymentList, setCurSelectedPaymentList] = useState()
  const [curQrId, setCurQrId]  = useState()
  const [err,setErr] = useState()

  const [active, setActive] = useState('1')
  const [timeoutTrue, setTimeOutTrue] = useState(false)

  const [tokenExpire, setTokenExpire] = useState(false)
  const [redirectURl, setRedirectURL] = useState('')
  const [reason, setReason] = useState('')

  const [response, setResponse] = useState(null);
  const [mpuFormData, setMPUFormData] = useState({})
  const [mpuUrl, setMpuUrl] = useState()
  const [mpuBody, setMpubody] = useState()
  const [mpucomfirm, setMpuComfirm] = useState(false)

  const navigate = useNavigate()
  const location = useLocation();
  
  const [firstNameGC, setFirstNameGC] = useState()
  const [lastNameGC, setLastNameGC] = useState()
  const [emailGC, setEmailGC] = useState()
  const [PhGC, setPhGC] = useState()
  const [city, setCity] = useState();
  const [country, setCountry] = useState("MM");
  const [address1, setAddress1] =useState("")

  const [decodedGCData, setDecodedGCData] = useState({})
  const [gcerror, setGCError] = useState()
  const [decodedGCURL, setDecodedGCURL]  = useState()
  
  const refContainer = useRef(null);
  const [changeLanguage, setChangeLanguage] = useState(false)
  const [data, setdata] = useState()
  const [selectedLanguage, setSelectedLanguage] = useState('US'); // Default to English (United States)

  const [debuggingKBZInappURL, setDebuggingKBZInappURL] = useState()


  const handleChange = (event) => {
    // Ensure the input does not exceed the maximum length
    if (event.target.value.length <= 40) {
      setAddress1(event.target.value);
    }
  };

  const handleChangeLanguage = (value) => {
    setSelectedLanguage(value);
    if (value === "MM") {
      setChangeLanguage(true);
      localStorage.setItem("changeLanguage", true)
    }
    if (value === "US") {
      setChangeLanguage(false);
      localStorage.setItem("changeLanguage", false)

    }
  };


 

  const fetchExactData = (one) => {
    if (changeLanguage === false) {
      one.map((languages) => {
        setdata(languages.english);
      });
    }
    if (changeLanguage === true) {
      one.map((languages) => {
        setdata(languages.myanmar);
      });
    }
  };
  useEffect(() => {
    fetchExactData(datas);
  }, [fetchExactData]);

  useEffect(() => {
    // Maintain a history of visited routes
    const history = [location.pathname];

    const handlePopState = () => {
      // Check if the user is trying to go back further than your recorded history
      if (history.length > 1) {
        history.pop(); // Remove the current route from history
        navigate(history[history.length - 1]); // Navigate to the previous route
      } else {
        // Prevent further navigation (optional)
        // You can choose to show a message or take some other action
        // For example, alert("You cannot go back any further.");
        window.history.forward(); // Move forward to prevent going back
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener('popstate', handlePopState);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, location.pathname]);



  const mpuSubmit = () => {
    setMpuComfirm(true)
    fetch(`https://payment-backend.ethicaldigit.com/checkout/choosePayment`, {
      method: "POST",
      headers: {
        "Content-Type": 'Application/JSON',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({paymentListID: selectedPaymentList?.paymentList?._id})
  })
      .then((response) => response.json())
      .then((json) => {
        console.log(json)
        if(json?.success) {
          if(json?.token) {
            localStorage.setItem("token", json?.token)
            var currentUrl = window.location.href;
            var url = new URL(currentUrl);
            url.searchParams.set('token', json?.token);
            window.history.replaceState({}, '', url.toString());
          }
          if(json?.data) {
            const decodedMPUData = jwtDecode(json?.data, 'WUc0WktjN1hXYjVDS0xPZUg4VGVRQjJLVVdRYTp0emtaT1J0X3hRb2FFOWNhVnhMbHRUOWt4SDhh');
            setMPUFormData(decodedMPUData)
            setMpuUrl(decodedMPUData?.url)
            setMpubody(decodedMPUData?.body)
            console.log(decodedMPUData)

            setTimeout(() => {
              const mpusubmit = document.getElementById("mpusubmit");
              if (mpusubmit) {
                mpusubmit.click();
                setMpuComfirm(false)
                window.addEventListener('popstate', function (event) {
                  // Prevent the default behavior of the back button
                  event.preventDefault();
                
                  // Optionally, you can display a message or take another action
                  alert("Back button is disabled on this page.");
                });

              } else {
                console.error("Element with ID 'mpusubmit' not found.");
              }
            }, 3000); 
          }
        }
      })

  }

  useEffect(() => {
    const storageToken = localStorage.getItem("token")
    function getQueryParam(name) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    }
    const token = getQueryParam('token');

    if(!token && storageToken) {
      var currentUrl = window.location.href;
    var url = new URL(currentUrl);
    url.searchParams.set('token', storageToken);
    window.history.replaceState({}, '', url.toString());
    window.location.reload();
    } else {
      localStorage.setItem('token', token);
    }
    
  },[])


  console.log('selectedPaymentList', selectedPaymentList)
  // console.log('selectpaylist', curSelectedPaymentList)
  // console.log('selectedPaymentList', selectedPaymentList)
  console.log('curPaymentType', curPaymentType)
  console.log('curSelectedPaymentList', curSelectedPaymentList)
  console.log('curQrId', curQrId);
  console.log(ranges)
  // console.log('selectedPaymentList', selectedPaymentList)
  console.log('curPaymentList', curPaymentList)
  // console.log('qrData !== null', qrData !== null)


  useEffect(() => {
    if(curPaymentType == 'E-Wallet') {
      setCurSelectedPaymentList(selectedPaymentList?.paymentList?.name)
    } else if( curPaymentType == "Local Card") {
      setCurSelectedPaymentList("MPU PAY")
    } else if( curPaymentType == "Web Pay")  {
      setCurSelectedPaymentList("Citizen Pay")
    } else if( curPaymentType == "Global Card")  {
      setCurSelectedPaymentList("JCB")
    }
  },[curPaymentType, selectedPaymentList])

  useEffect(() => {
    if(!selectedPaymentList) {
      const filterInitialPyamnetList = curPaymentList?.filter((each) => each?.paymentList?.type?.type === curPaymentType);
      setSelectedPaymentList(curPaymentList?.[0])
      // setSelectedPaymentList(filterInitialPyamnetList)

    }
  },[curPaymentList])

  // useEffect(() => {
  //   // if(!curQrId) {
  //      const filteredList = ranges?.filter((item) => item?.paymentList?.code == `${curSelectedPaymentList?.split(" ")[0]}_QR`);
  //   // const filteredList = ranges?.filter((each) => each?.paymentList?.code == `${selectedPaymentList?.paymentList?.code?.split("_")[0]}_QR`);
  //   setCurQrId(filteredList?.[0]?.paymentList?._id)
  //   console.log(filteredList)
  //   // }
   

  // },[curSelectedPaymentList])

  useEffect(() => {
        const payments = ranges?.filter((each) => each?.paymentList?.type?.type === "E-Wallet");
        setCurpaymentList(payments)
  },[ranges])



  const toggle = tab => {
    if (active !== tab) {
      setActive(tab)
    }
  }


  const urlSearchParams = new URLSearchParams(window.location.search);
  const tokenB = urlSearchParams.get('token');

 

  useEffect(() => {
    const payment = ranges?.filter((each) => each?.paymentList?.type?.type === curPaymentType);
    setCurpaymentList(payment)
  },[curPaymentType, ranges])
  
  

console.log("decodedToken", decodedData);

useEffect(() => {
  const socket = io("https://payment-backend.ethicaldigit.com");

  socket.on("message", (data) => {
    console.log(data)
  });

    
  socket.on("callbackFrontend", (data) => {
    console.log("callbackFrontend",data)
    console.log("decodedData", decodedData);
    console.log("transactionID", decodedData?.transactionID);
    console.log("condition",data?.transactionID == decodedData?.transactionID,data?.transactionID, decodedData?.transactionID )
    
    const id = localStorage.getItem("transactionID")
    console.log(id)
    console.log(data?.frontendUrl)

    
    if(data?.transactionID == id) {
      setSuccess(true)
    console.log(data?.frontendUrl)

      localStorage.setItem('callbackurl', data?.frontendUrl)
      setTimeOut('');
      setRemainingTime('');
    }
  });

  
},[])

useEffect(() => {
  if(success) {
    setSuccessModalOpen(false)
    // window.location.href = '/success';
    navigate('/success');
  }
},[success])

useEffect(() => {
  if(timeout) {
     const endTime = new Date(timeout);

  const updateTimer = () => {
    const currentTime = new Date().getTime();
    const difference = endTime - currentTime;

    if (difference > 0) {
      const seconds = Math.floor((difference / 1000) % 60);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const hours = Math.floor((difference / 1000 / 3600) % 24);
      const days = Math.floor(difference / (1000 * 3600 * 24));

      const remainingTimeString = `${minutes} minutes, ${seconds} seconds`;

      setRemainingTime(remainingTimeString);
    } else {
      setTimeOutTrue(true)
      setRemainingTime('Time out');
      setTimeOut('')
      setSuccessMsg('')
      setErr('')
      // setSuccessModalOpen(false)
      const socket = io("https://payment-backend.ethicaldigit.com");

      socket.emit("checkoutTimeout", {"transactionID": decodedData?.transactionID, 'payment_method': selectedPaymentList?.paymentList?.code});
    }
  };



  const timer = setInterval(updateTimer, 1000);

  return () => {
    clearInterval(timer);
  };
  }
 
}, [timeout]);

const retryOnce = () => {
  setSuccessModalOpen(false)
  setTimeOutTrue(false)
  setRemainingTime('')
}

  useEffect(() => {
    
    setToken(tokenB);
    const decodedToken = jwtDecode(tokenB, 'WUc0WktjN1hXYjVDS0xPZUg4VGVRQjJLVVdRYTp0emtaT1J0X3hRb2FFOWNhVnhMbHRUOWt4SDhh');
    // console.log(decodedToken);
    setDecodedData(decodedToken);
    localStorage.setItem("transactionID",decodedToken?.transactionID)

  }, [tokenB]);



  useEffect(() => {
    if(decodedData?.merchantID) {
      fetch(`https://payment-backend.ethicaldigit.com/checkout`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`
            },
        })
            .then((response) => response.json())
            .then(json => {
              console.log('token',json)
              if(json?.data) {
                const decodedRanges = jwtDecode(json?.data, 'WUc0WktjN1hXYjVDS0xPZUg4VGVRQjJLVVdRYTp0emtaT1J0X3hRb2FFOWNhVnhMbHRUOWt4SDhh');
              setRanges(decodedRanges?.merchantPaymentList?.ranges)
              setType(decodedRanges?.types)
              }
              
             
              if(json?.err == 3400) {
                if(json?.success) {
                  // window.location.href = '/success';
                  localStorage.setItem('callbackurl', json?.url)
                  navigate('/success');

                } else {
                  const socket = io("https://payment-backend.ethicaldigit.com");

                socket.emit("checkoutTimeout", {"transactionID": decodedData?.transactionID, 'reason': 'token'});

                socket.on("frontendURL", (data) => {
                  
                  if(data.err == 3400) {
                    setReason(data?.msg)
                    setTokenExpire(true)
                    setRedirectURL(data?.url)
                  }
                  
                })
                }
                
              }
            })
            .catch(e => console.log(e))
    }
    
  },[decodedData])

  const comfirmPaymentList = () => {
    setClickComfirm(true)
    fetch(`https://payment-backend.ethicaldigit.com/checkout/choosePayment`, {
            method: "POST",
            headers: {
              "Content-Type": 'Application/JSON',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({paymentListID: selectedPaymentList?.paymentList?._id, customerPhone: phone})
        })
            .then((response) => response.json())
            .then(json => {
              if(json?.success) {
                console.log('phone res', json)
                setSuccessModalOpen(true)
                setSuccessMsg(json?.msg)
                setTimeOut(json?.expiredAt)
                setClickComfirm(false)
                setErr('')
              } else {
                setErr(json?.msg)
                setClickComfirm(false)
              }
              if(json?.err == 3400) {
                if(json?.success) {
                  // window.location.href = '/success';
                  navigate('/success');

                } else {
                  const socket = io("https://payment-backend.ethicaldigit.com");

                socket.emit("checkoutTimeout", {"transactionID": decodedData?.transactionID, 'reason': 'token'});

                socket.on("frontendURL", (data) => {
                  
                  if(data.err == 3400) {
                    setReason(data?.msg)
                    setTokenExpire(true)
                    setRedirectURL(data?.url)
                  }
                  
                })
                }
              }
            })
            .catch(e => console.log(e))
    
  }

  const GCComfirm = () => {
    setClickComfirm(true)
    setGCError('')
    fetch(`https://payment-backend.ethicaldigit.com/checkout/choosePayment`, {
            method: "POST",
            headers: {
              "Content-Type": 'Application/JSON',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({paymentListID: selectedPaymentList?.paymentList?._id, firstName: firstNameGC, lastName: lastNameGC, email: emailGC, phone: PhGC, city, country, address1})
        })
            .then((response) => response.json())
            .then(json => {
              if(json?.success) {
                console.log('json', json)
                if(json?.token) {
                  var currentUrl = window.location.href;
                  var url = new URL(currentUrl);
                  url.searchParams.set('token', json?.token);
                  window.history.replaceState({}, '', url.toString());
                }
                if(json?.data) {
                  const decodedGCDatas = jwtDecode(json?.data, 'WUc0WktjN1hXYjVDS0xPZUg4VGVRQjJLVVdRYTp0emtaT1J0X3hRb2FFOWNhVnhMbHRUOWt4SDhh');
                  setDecodedGCData(decodedGCDatas?.body)
                  setDecodedGCURL(decodedGCDatas?.url)
                  console.log(decodedGCDatas)
                  setTimeout(() => {
                    const gcsubmit = document.getElementById("gcsubmit");
                    if (gcsubmit) {
                      gcsubmit.click();
                      setCurPaymentType("Global Card")
                      setCurSelectedPaymentList("JCB")
                      setClickComfirm(false)
                      window.addEventListener('popstate', function (event) {
                        // Prevent the default behavior of the back button
                        event.preventDefault();
                      
                        // Optionally, you can display a message or take another action
                        alert("Back button is disabled on this page.");
                      });
      
                    } else {
                      console.error("Element with ID 'gcsubmit' not found.");
                    }
                  }, 3000); 
                }
                
              } else {
                setGCError(json?.msg)
                setClickComfirm(false)
              }
              if(json?.err == 3400) {
                if(json?.success) {
                  // window.location.href = '/success';
                  navigate('/success');

                } else {
                  const socket = io("https://payment-backend.ethicaldigit.com");

                socket.emit("checkoutTimeout", {"transactionID": decodedData?.transactionID, 'reason': 'token'});

                socket.on("frontendURL", (data) => {
                  
                  if(data.err == 3400) {
                    setReason(data?.msg)
                    setTokenExpire(true)
                    setRedirectURL(data?.url)
                  }
                  
                })
                }
              }
            })
            .catch(e => console.log(e))
  }

  const PaywithKBZINAPP = () => {
    setClickComfirm(true)
    fetch(`https://payment-backend.ethicaldigit.com/checkout/choosePayment`, {
            method: "POST",
            headers: {
              "Content-Type": 'Application/JSON',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({paymentListID: selectedPaymentList?.paymentList?._id})
        })
            .then((response) => response.json())
            .then(json => {
              if(json?.success) {
                if(json?.token) {
                  var currentUrl = window.location.href;
                  var url = new URL(currentUrl);
                  url.searchParams.set('token', json?.token);
                  // window.history.replaceState({}, '', url.toString());
                }
                setClickComfirm(false)
                  // setDebuggingKBZInappURL(json?.url)
                  // window.open(json?.url);
                  // window.location.href = json?.url;

                  // Open a new tab/window
                  const newWindow = window.open('', '_blank');

                  // Redirect the current window or the new window based on the response URL
                  if (newWindow) {
                    newWindow.location.href = json?.url;
                  } else {
                    // Fallback if the new window couldn't be opened (e.g., popup blocker)
                    window.location.href = json?.url;
                  }


              } else {
                // setErr(json?.msg)
                console.log(json)
                setClickComfirm(false)
                
              }
              if(json?.err == 3400) {
                if(json?.success) {
                  // window.location.href = '/success';
                  navigate('/success');

                } else {
                  const socket = io("https://payment-backend.ethicaldigit.com");

                socket.emit("checkoutTimeout", {"transactionID": decodedData?.transactionID, 'reason': 'token'});

                socket.on("frontendURL", (data) => {
                  
                  if(data.err == 3400) {
                    setReason(data?.msg)
                    setTokenExpire(true)
                    setRedirectURL(data?.url)
                  }
                  
                })
                }
              }
            })
            .catch(e => console.log(e))
    
  }

  const getQRData = () => {
    setClickComfirm(true)
    fetch(`https://payment-backend.ethicaldigit.com/checkout/choosePayment`, {
                                method: "POST",
                                headers: {
                                  "Content-Type": 'Application/JSON',
                                  Authorization: `Bearer ${token}`
                                },
                                body: JSON.stringify({paymentListID: curQrId})
                            })
                                .then((response) => response.json())
                                .then(json => {
                                  console.log(json)
                                  if(json?.success) {
                                    if(json?.token) {
                                      var currentUrl = window.location.href;
                                      var url = new URL(currentUrl);
                                      url.searchParams.set('token', json?.token);
                                      window.history.replaceState({}, '', url.toString());
                                    }
                                    setQrData(json?.qrdata)
                                    console.log('QR response', json)
                                    setTimeOut(json?.expiredAt)
                                    setClickComfirm(false)
  
                                  }
                                  if(json?.err == 3400) {
                                    if(json?.success) {
                                      // window.location.href = '/success';
                                      navigate('/success');

                                    } else {
                                      const socket = io("https://payment-backend.ethicaldigit.com");
                    
                                    socket.emit("checkoutTimeout", {"transactionID": decodedData?.transactionID, 'reason': 'token'});
                    
                                    socket.on("frontendURL", (data) => {
                                      
                                      if(data.err == 3400) {
                                        setReason(data?.msg)
                                        setTokenExpire(true)
                                        setRedirectURL(data?.url)
                                      }
                                      
                                    })
                                    }
                                  }
                                })
                                .catch(e => console.log(e))
  }

  const redirect = () => {
    const redirectWindow = window.open(redirectURl, 'myRedirectWindow');

  if (redirectWindow) {
    // The redirect URL is already open, focus on that window
    redirectWindow.focus();
  } else {
    // The redirect URL is not open, open it in a new tab/window
    // window.open(redirectURl, '_blank');
    window.open(redirectURl);

  }
}

  return (
    <div style={{background: '#F5F5F5'}} className="App h-100 border-1">
      <div style={{ background: 'white'}}>
        <div className='container d-flex justify-content-between align-items-center'>
          {/* <img style={{width: '224px'}} className='col-lg-2 ms-lg-3 col-6 py-4 text-start' src={logo} alt="" /> */}
          <img style={{width: '70px'}} className='col-lg-2 ms-lg-3 col-6 text-start py-3' src={logo} alt="" />

          <div className="button">
          {/* <div className="languagesdiv "> */}
          <div>
          <Flags
            selected={selectedLanguage}
            countries={['US', 'MM']}
            customLabels={{ US: 'English', MM: 'မြန်မာ' }}
            onSelect={handleChangeLanguage}
          />
            {/* <select
              name="languages"
              // className="beforeicon relative"
              ref={refContainer}
              onChange={handleChange}
            >
              <option value="english">
              <img
        style={{ width: '20px', height: '20px', marginRight: '5px', border: '2px solid red' }}
        src="https://muralsyourway.vtexassets.com/arquivos/ids/236018/American-Flag-Wave-Wallpaper-Mural.jpg?v=638165395901470000"
        alt=""
      />English</option>
              <option value="myanmar">မြန်မာ</option>
            </select> */}
          </div>
          </div>
        </div>
      </div>
      <div className='container d-lg-flex flex-row-reverse  justify-content-around pt-lg-5'>
      <div style={{ background: '#FFF', height: '200px',}} className='p-3 my-3 my-lg-0 rounded merchantInfo'>
          <div className='d-flex justify-content-between align-items-center mb-4'>
            <img className=' rounded' style={{ maxHeight: '80px', width: '99px', height: '64px' , objectFit: 'contain' }} src={`${decodedData?.logo}`} alt="" />
          <p style={{fontSize: '24px', fontFamily: 'Nunito Sans', fontWeight: '600', lineHeight: '32px'}} className='m-0'>{new Intl.NumberFormat('en-US').format(decodedData?.amount)} {data?.mmk}</p>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <p style={{fontSize: '18px', fontFamily: 'Nunito Sans', fontWeight: '300', lineHeight: '32px'}} className='m-0'>{data?.merchantName}</p>
            <p style={{fontSize: '18px', fontFamily: 'Nunito Sans', fontWeight: '400', lineHeight: '32px'}} className='m-0'>{decodedData?.merchantName}</p>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <p style={{fontSize: '18px', fontFamily: 'Nunito Sans', fontWeight: '300', lineHeight: '32px'}} className='m-0'>{data?.InvoiceId}</p>
            <p style={{fontSize: '18px', fontFamily: 'Nunito Sans', fontWeight: '400', lineHeight: '32px'}} className='m-0'>{decodedData?.invoiceNo}</p>
          </div>
        </div>
        <div style={{borderRadius: '4px'}} className='paymentMethodSide bg-white px-lg-4 px-2 py-4 mb-4'>
          <h4 className='mb-3 text-start col-12 selectpaymentTitle'>{data?.title}</h4>
          {types?.length == 0 && (
            <>
            <div className='border text-start col-12 p-2 px-2 rounded shadow mb-3 br'>
            <div class="comment br animate w80"></div>
            <div class="profilePic-container">
                      <div class="wrapper">
                          <div class="profilePic animate din">      </div>
                      </div>
                      <div class="wrapper">
                          <div class="profilePic animate din">      </div>
                      </div>
                      <div class="wrapper">
                          <div class="profilePic animate din">      </div>
                      </div>
              </div>
            </div>
            <div className='border text-start col-12 p-2 px-2 rounded shadow mb-3 br'>
            <div class="comment br animate w80"></div>
            <div class="profilePic-container">
                      <div class="wrapper">
                          <div class="profilePic animate din">      </div>
                      </div>
                      <div class="wrapper">
                          <div class="profilePic animate din">      </div>
                      </div>
                      <div class="wrapper">
                          <div class="profilePic animate din">      </div>
                      </div>
                      <div class="wrapper">
                          <div class="profilePic animate din">      </div>
                      </div>
              </div>
            </div>
            <div className='border text-start col-12 p-2 px-2 rounded shadow mb-3 br'>
            <div class="comment br animate w80"></div>
            <div class="profilePic-container">
                      <div class="wrapper">
                          <div class="profilePic animate din">      </div>
                      </div>
                      <div class="wrapper">
                          <div class="profilePic animate din">      </div>
                      </div>
              </div>
            </div>
            </>
          )}
          {types?.map((eachType, index) => {
            console.log('eachType', eachType)
            const payment = ranges?.filter((each) => each?.paymentList?.type?.type === eachType);
            console.log(payment)
            
            if (payment.length > 0) {
              return (
                <>{curPaymentType === eachType ? (
                  <div 
                  onClick={(e) => {
                    window.scrollTo(0, document.documentElement.scrollHeight);
                    console.log(e.target.id)
                    if (e.target.id == 'child') {
                      console.log('eachType', eachType)
                      setCurPaymentType(eachType);
                    } else {
                      setCurPaymentType(curPaymentType === eachType ? "" : eachType);
                    }
                    setRemainingTime('');
                    setQrData('')
                  }}
                  
                  key={eachType}
                  style={{cursor: 'pointer', position: 'relative'}}
                  // className={`border text-start col-12 p-3 px-2 paymentParentBox rounded shadow mb-3 ${curPaymentType == eachType && "activePaymentType"}`}>
                  className={`border ${index === types.length - 1 ? 'mb-0' : 'mb-3'} text-start col-12 py-4 px-lg-3 px-2 rounded ${curPaymentType == eachType && "activePaymentType"}`}>
                    {/* <div className='d-flex justify-content-between'> */}
                    <h5 style={{
                      fontFamily: 'Nunito Sans',
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '32px',
                    }} >{eachType}</h5>
                  <div>
                      {payment.map((item, index) => (
                        <>
                    {curPaymentType !== "Global Card" ? 
                    (<div id='child' className={` ${index === payment.length - 1 ? 'noBorderBottom' : 'border-bottom'}`}>
                      <div
                      onClick={() => {
                        if(item?.paymentList?.name !== 'MPU PAY') {
                          setCurSelectedPaymentList(curSelectedPaymentList == item?.paymentList?.name ? '' : item?.paymentList?.name);
                        setSelectedPaymentList(item);
                        toggle('1')
                        setQrData('')
                        setRemainingTime('');
                        setTimeOut('')
                        setTimeOutTrue(false)
                        const filteredList = ranges?.filter((item) => item?.paymentList?.code == `${item?.paymentList?.code?.split("_")[0]}_QR`);
                        filteredList?.map((each) => {
                          if(each?.paymentList?.name == `${item?.paymentList?.name?.split(" ")[0]} QR`) {
                            setCurQrId(each?.paymentList?._id);
                          }
                        })
                        } else {
                          console.log('i am mpu')
                          setCurSelectedPaymentList(curSelectedPaymentList == item?.paymentList?.name ? '' : item?.paymentList?.name);
                          setSelectedPaymentList(item);
                          setQrData('')
                          setRemainingTime('');
                          setTimeOut('')
                          setTimeOutTrue(false)
                        }
                        }}
                        id='child'
                      style={{width: '100%', height: '100%', cursor: 'pointer'}} className={`d-flex align-items-center p-2 rounded me-2 pb-3 pt-3`}>
                        <input
                          id='child'
                          checked={curSelectedPaymentList === item?.paymentList?.name}
                          type="radio" name="" className='me-2' style={{width: '24px', height: '24px', cursor: 'pointer'}}  ></input>
                  <img id='child' style={{ width: '40px', height: '40px', objectFit: 'contain', borderRadius: '5px' }} 
                    src={`${item?.paymentList?.logo ? `https://cbs-payment.s3.ap-southeast-1.amazonaws.com/payment/logo/${item?.paymentList?.logo}` : 'https://play-lh.googleusercontent.com/cnKJYzzHFAE5ZRepCsGVhv7ZnoDfK8Wu5z6lMefeT-45fTNfUblK_gF3JyW5VZsjFc4'}`} alt="" />
                    <p id='child' className='m-0 ms-1 paymentname'>{item?.paymentList?.name?.split(" ")[0]} {item?.paymentList?.name?.split(" ")[0] != 'MPU' && data?.pay}</p>
                    </div>
                    {/* {curSelectedPaymentList === item?.paymentList?.name ? ( */}
                    {/* --------------------------------------------------------------------------------------------------------------------------------- */}
                    {curSelectedPaymentList !== "MPU PAY" && curSelectedPaymentList !== "JCB" && curSelectedPaymentList === item?.paymentList?.name ? (

                      <div>
                    <React.Fragment>
                      <Nav style={{width:"100%", paddingLeft: '40px'}} className='d-flex border-0 justify-content-between text-center m-auto' tabs>
                        <button id='child' className={` text-center py-2 ${active === '1' ? 'activetag' : 'normaltag'}`}
                        style={{background: 'white !important'}}
                        onClick={() => {
                          toggle('1')
                          setQrData('')
                          setRemainingTime('');
                          setTimeOut('')
                          setTimeOutTrue(false)
                        }}
                        ><Smartphone id='child' style={{width: '18px', height: '18px'}} /> {data?.InAppPay}
                          <svg  style={{position: 'absolute', top: '-10px',right: '-10px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <rect width="20" height="20" fill="white"/>
                          <path d="M10.0003 1.66675C5.39783 1.66675 1.66699 5.39758 1.66699 10.0001C1.66699 14.6026 5.39783 18.3334 10.0003 18.3334C14.6028 18.3334 18.3337 14.6026 18.3337 10.0001C18.3337 5.39758 14.6028 1.66675 10.0003 1.66675ZM14.7562 8.08925L8.92283 13.9226C8.76032 14.0851 8.54699 14.1667 8.33366 14.1667C8.12033 14.1667 7.90699 14.0851 7.74449 13.9226L5.24449 11.4226C4.91866 11.0967 4.91866 10.5701 5.24449 10.2442C5.57033 9.91842 6.09699 9.91842 6.42283 10.2442L8.33366 12.1551L13.5778 6.91091C13.9037 6.58508 14.4303 6.58508 14.7562 6.91091C15.082 7.23675 15.082 7.76341 14.7562 8.08925Z" fill="black"/>
                          </svg>
                        </button>
                        {/* <p className='m-0 col-1'>or</p> */}
                        <button id='child' className={` h-auto text-center ${active === '2' ? 'activetag' : 'normaltag'}`}
                          style={{background: 'white !important'}}
                          onClick={() => {
                            toggle('2')
                            setErr('')
                            const filteredList = ranges?.filter((item) => item?.paymentList?.code == `${item?.paymentList?.code?.split("_")[0]}_QR`);
                            console.log('ranges', ranges)
                            console.log('item?.paymentList?.code', item?.paymentList?.code)
                            console.log('filteredList', filteredList)
                            filteredList?.map((each) => {
                              console.log('each?.paymentList?.name == `${item?.paymentList?.name?.split(" ")[0]}_QR`', each?.paymentList?.name == `${item?.paymentList?.name?.split(" ")[0]}_QR`, each?.paymentList?.name, `${item?.paymentList?.name?.split(" ")[0]}_QR`)
                              if(each?.paymentList?.name == `${item?.paymentList?.name?.split(" ")[0]} QR`) {
                                // setCurQrId(filteredList?.[0]?.paymentList?._id);
                                setCurQrId(each?.paymentList?._id);
                              }
                            })
                          }}
                        ><img id='child' style={{width: '18px', height: '18px'}} src={QR} alt="" /> {data?.QRCode}
                        <svg  style={{position: 'absolute', top: '-10px',right: '-10px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <rect width="20" height="20" fill="white"/>
                          <path d="M10.0003 1.66675C5.39783 1.66675 1.66699 5.39758 1.66699 10.0001C1.66699 14.6026 5.39783 18.3334 10.0003 18.3334C14.6028 18.3334 18.3337 14.6026 18.3337 10.0001C18.3337 5.39758 14.6028 1.66675 10.0003 1.66675ZM14.7562 8.08925L8.92283 13.9226C8.76032 14.0851 8.54699 14.1667 8.33366 14.1667C8.12033 14.1667 7.90699 14.0851 7.74449 13.9226L5.24449 11.4226C4.91866 11.0967 4.91866 10.5701 5.24449 10.2442C5.57033 9.91842 6.09699 9.91842 6.42283 10.2442L8.33366 12.1551L13.5778 6.91091C13.9037 6.58508 14.4303 6.58508 14.7562 6.91091C15.082 7.23675 15.082 7.76341 14.7562 8.08925Z" fill="black"/>
                          </svg>
                        </button>
                        
                        
                      </Nav>
                      <TabContent style={{paddingLeft: '40px'}} className='py-50' activeTab={active}>
                        <TabPane className='pb-2' tabId='1'>
                          {curSelectedPaymentList !== "KBZ PWA" ? (
                              <form action="">
                                <p className='py-3 m-0'>{data?.ayainfo}</p>
                          <InputGroup>
                              <InputGroupText id='child' className={`py-2 ${err && 'error'}`}>+95</InputGroupText>
                            <Input id='child' style={{padding: '8px 16px'}} onChange={(e) => setPhone(e.target.value)}  className={` ${err && 'error'}`} placeholder={data?.phPlaceholder} />
                          </InputGroup>
                          <p style={{color: 'red'}}>{err}</p>
                          
                          </form>
                          ) : (
                            <>
                            <BrowserView>
                            <Alert className='mt-4' color='warning' isOpen={true}>
                              <div className='alert-body'>
                                <AlertCircle size={15} />{' '}
                                <span className='ml-1' dangerouslySetInnerHTML={{ __html: data?.kpayWarning }}>
                                
                                </span>
                                </div>
                            </Alert>
                            </BrowserView>
                            <MobileView>
                            {/* <input className='mt-2' type="checkbox" /> */}
                            <p className='py-3 m-0'>{data?.kbzinfo}</p>

                          {/* <span className='ps-1' style={{fontSize: '.8rem'}}>By Choosing, you agree to the <a href="#">Terms & Conditions</a></span><br /> */}
                          {/* {clickComfirm ? (
                              <Button  className='btnstyle' style={{width: '100px'}}><Spinner style={{width: '25px', height: '25px'}} /></Button>
                            ) : (
                            <Button className='btnstyle' onClick={PaywithKBZINAPP}>Pay With KBZ</Button>
                            )} */}
                            {/* <h6 className='pt-3'>Power by <a href="#">Ethical Digit</a></h6> */}
                            </MobileView>
                            </>
                          )} 
                    
                        </TabPane>
                        <TabPane tabId='2' className='pb-2'>
                          <p className='pt-2'>{data?.qrinfo} </p>
                          
                        </TabPane>
                        
                      </TabContent>
                    </React.Fragment>
                      
                    </div>
                    ) : ('')}
                    
                    </div>) : (
                      <>
                      {/* {payment.map((item, index) => ( */}
                      <img id='child' style={{ width: '40px', height: '40px', objectFit: 'contain', borderRadius: '5px', marginRight: '8px' }} 
                    src={`${item?.paymentList?.logo ? `https://cbs-payment.s3.ap-southeast-1.amazonaws.com/payment/logo/${item?.paymentList?.logo}` : 'https://play-lh.googleusercontent.com/cnKJYzzHFAE5ZRepCsGVhv7ZnoDfK8Wu5z6lMefeT-45fTNfUblK_gF3JyW5VZsjFc4'}`} alt="" />
                    {payment.length - 1 === index ? (
                      <>
                      <br />
                      <form className='pt-3 d-flex justify-content-between align-items-center flex-wrap'>
                            <Input type='text' id='child' style={{padding: '8px 16px', width: "48%"}} onChange={(e) => setFirstNameGC(e.target.value)} value={firstNameGC} className="col-6" placeholder={data?.firstName} />
                            <Input type='text' id='child' style={{padding: '8px 16px', width: "48%"}} onChange={(e) => setLastNameGC(e.target.value)} value={lastNameGC} className="col-6" placeholder={data?.lastName} />
                            <Input type='email' id='child' style={{padding: '8px 16px', width: "48%"}} onChange={(e) => setEmailGC(e.target.value)} value={emailGC} className="mt-3" placeholder={data?.email} />
                            <Input type='text' id='child' style={{padding: '8px 16px', width: "48%"}} onChange={(e) => setPhGC(e.target.value)} value={PhGC} className="mt-3" placeholder={data?.phPlaceholder} />
                            <select id='child' style={{padding: '8px 16px', width: "48%"}} onChange={(e) => setCountry(e.target.value)} defaultValue={country} name="country" class="form-control mt-3">
                              <option value="Afghanistan">Afghanistan</option>
                              <option value="Åland Islands">Åland Islands</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="American Samoa">American Samoa</option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antarctica">Antarctica</option>
                              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Aruba">Aruba</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                              <option value="Botswana">Botswana</option>
                              <option value="Bouvet Island">Bouvet Island</option>
                              <option value="Brazil">Brazil</option>
                              <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                              <option value="Brunei Darussalam">Brunei Darussalam</option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">Cayman Islands</option>
                              <option value="Central African Republic">Central African Republic</option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Christmas Island">Christmas Island</option>
                              <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                              <option value="Cook Islands">Cook Islands</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cote D'ivoire">Cote D'ivoire</option>
                              <option value="Croatia">Croatia</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">Czech Republic</option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">Dominican Republic</option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">Equatorial Guinea</option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                              <option value="Faroe Islands">Faroe Islands</option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">French Guiana</option>
                              <option value="French Polynesia">French Polynesia</option>
                              <option value="French Southern Territories">French Southern Territories</option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Gibraltar">Gibraltar</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guernsey">Guernsey</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-bissau">Guinea-bissau</option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                              <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Isle of Man">Isle of Man</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jersey">Jersey</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                              <option value="Korea, Republic of">Korea, Republic of</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                              <option value="Liechtenstein">Liechtenstein</option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macao">Macao</option>
                              <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">Marshall Islands</option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mayotte">Mayotte</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                              <option value="Moldova, Republic of">Moldova, Republic of</option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montenegro">Montenegro</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="MM">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Netherlands Antilles">Netherlands Antilles</option>
                              <option value="New Caledonia">New Caledonia</option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Niue">Niue</option>
                              <option value="Norfolk Island">Norfolk Island</option>
                              <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">Papua New Guinea</option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Pitcairn">Pitcairn</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russian Federation">Russian Federation</option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Helena">Saint Helena</option>
                              <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                              <option value="Saint Lucia">Saint Lucia</option>
                              <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                              <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Serbia">Serbia</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">Solomon Islands</option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                              <option value="Thailand">Thailand</option>
                              <option value="Timor-leste">Timor-leste</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">United Arab Emirates</option>
                              <option value="United Kingdom">United Kingdom</option>
                              <option value="United States">United States</option>
                              <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Viet Nam">Viet Nam</option>
                              <option value="Virgin Islands, British">Virgin Islands, British</option>
                              <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                              <option value="Wallis and Futuna">Wallis and Futuna</option>
                              <option value="Western Sahara">Western Sahara</option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                              </select>
                            <Input type='text' id='child' style={{padding: '8px 16px', width: "48%"}} maxLength={50} onChange={(e) => setCity(e.target.value)} value={city} className="mt-3" placeholder={data?.city} />
                            {/* <Input type='text' id='child' style={{padding: '8px 16px', width: "100%"}} onChange={(e) => setAddress1(e.target.value)} value={address1} className="mt-3" placeholder={data?.address} /> */}
                            <div id='child' style={{width: '100%'}}>
     <Input type='text' id='child' style={{padding: '8px 16px', width: "100%"}} onChange={handleChange} maxLength={40} value={address1} className="mt-3" placeholder={data?.address} />

      <p style={{textAlign: 'right'}}>Characters left: {40 - address1?.length}</p>
    </div>
                            {/* <Input type='text' id='child' style={{padding: '8px 16px', width: "48%"}} onChange={(e) => setAddress1(e.target.value)} value={address1} className="mt-3" placeholder='Address1' /> */}
                      </form>
                      <form style={{visibility: 'hidden', height: '0'}} id="payment_form" action={decodedGCURL} method="post">
                        <input id="access_key" name="access_key" value={decodedGCData?.access_key} />
                        <input id="profile_id" name="profile_id" value={decodedGCData?.profile_id} />
                        <input id="transaction_uuid" name="transaction_uuid" value={decodedGCData?.transaction_uuid} />
                        <input id="signed_field_names" name="signed_field_names" value={decodedGCData?.signed_field_names} />
                        <input id="unsigned_field_names" name="unsigned_field_names" value={decodedGCData?.unsigned_field_names} />
                        <input id="signed_date_time" name="signed_date_time" value={decodedGCData?.signed_date_time} />
                        <input id="locale" name="locale" value={decodedGCData?.locale} />
                        {/* <input id="allow_payment_token_update" name="allow_payment_token_update" value={decodedGCData?.allow_payment_token_update} /> */}
                        <input id="auth_indicator" name="auth_indicator" value={decodedGCData?.auth_indicator} />
                        <input id="auth_type" name="auth_type" value={decodedGCData?.auth_type}/>
                        <input id="bill_to_address_city" name="bill_to_address_city" value={decodedGCData?.bill_to_address_city} />
                        <input id="bill_to_address_country" name="bill_to_address_country" value={decodedGCData?.bill_to_address_country} />
                        <input id="bill_to_address_line1" name="bill_to_address_line1" value={decodedGCData?.bill_to_address_line1} />
                        <input id="bill_to_email" name="bill_to_email" value={decodedGCData?.bill_to_email} />
                        <input id="bill_to_forename" name="bill_to_forename" value={decodedGCData?.bill_to_forename} />
                        <input id="bill_to_phone" name="bill_to_phone" value={decodedGCData?.bill_to_phone} />
                        <input id="bill_to_surname" name="bill_to_surname" value={decodedGCData?.bill_to_surname} />
                        {/* <input id="card_cvn" name="card_cvn" value={decodedGCData?.card_cvn} /> */}
                        {/* <input id="card_expiry_date" name="card_expiry_date" value={decodedGCData?.card_expiry_date} /> */}
                        {/* <input id="card_number" name="card_number" value={decodedGCData?.card_number} /> */}
                        {/* <input id="card_type" name="card_type" value={decodedGCData?.card_type} /> */}
                        <input id="company_tax_id" name="company_tax_id" value={decodedGCData?.company_tax_id} />
                        {/* <input id="bill_address1" name="bill_address1" value={decodedGCData?.bill_address1} /> */}
                        {/* <input id="bill_city" name="bill_city" vvalue={decodedGCData?.bill_city} /> */}
                        {/* <input id="bill_country" name="bill_country" value={decodedGCData?.bill_country} /> */}
                        {/* <input id="customer_email" name="customer_email" value={decodedGCData?.customer_email} /> */}
                        {/* <input id="customer_lastname" name="customer_lastname" value={decodedGCData?.customer_lastname} /> */}
                        <input id="transaction_type" name="transaction_type" value={decodedGCData?.transaction_type} />
                        <input id="reference_number" name="reference_number" value={decodedGCData?.reference_number} />
                        <input id="amount" name="amount" value={decodedGCData?.amount} />
                        <input id="currency" name="currency" value={decodedGCData?.currency} />
                        <input id="signature" name="signature" value={decodedGCData?.signature} />
                        <input type="submit" id="gcsubmit" name="submit" value="Submit" />
                      </form>
                      {gcerror && <p className='pt-3 mb-0' style={{color: 'red'}}>{gcerror} !!!</p>}
                      </>
                    ) : ""}
                    

                        {/* <img className='me-2' style={{ width: '32px', height: '32px', objectFit: 'contain', borderRadius: '5px' }} 
                             src={`${item?.paymentList?.logo ? `https://cbs-payment.s3.ap-southeast-1.amazonaws.com/payment/logo/${item?.paymentList?.logo}` : 'https://play-lh.googleusercontent.com/cnKJYzzHFAE5ZRepCsGVhv7ZnoDfK8Wu5z6lMefeT-45fTNfUblK_gF3JyW5VZsjFc4'}`} alt="" /> */}
                       
                       {/* ))} */}
                       </>
                    ) }
                    </>
                    ))}
                  </div>
                  {/* <svg style={{position: 'absolute', top: '-10px',right: '-10px'}} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path d="M14.0003 0.666748C6.63633 0.666748 0.666992 6.63608 0.666992 14.0001C0.666992 21.3641 6.63633 27.3334 14.0003 27.3334C21.3643 27.3334 27.3337 21.3641 27.3337 14.0001C27.3337 6.63608 21.3643 0.666748 14.0003 0.666748ZM21.6097 10.9427L12.2763 20.2761C12.0163 20.5361 11.675 20.6667 11.3337 20.6667C10.9923 20.6667 10.651 20.5361 10.391 20.2761L6.39099 16.2761C5.86966 15.7547 5.86966 14.9121 6.39099 14.3907C6.91233 13.8694 7.75499 13.8694 8.27632 14.3907L11.3337 17.4481L19.7243 9.05741C20.2457 8.53608 21.0883 8.53608 21.6097 9.05741C22.131 9.57875 22.131 10.4214 21.6097 10.9427Z" fill="black"/>
                  </svg> */}
                   <svg style={{position: 'absolute', top: '-14px',right: '-14px'}} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <rect width="32" height="32" fill="white"/>
                    <path d="M16.0003 2.66675C8.63633 2.66675 2.66699 8.63608 2.66699 16.0001C2.66699 23.3641 8.63633 29.3334 16.0003 29.3334C23.3643 29.3334 29.3337 23.3641 29.3337 16.0001C29.3337 8.63608 23.3643 2.66675 16.0003 2.66675ZM23.6097 12.9427L14.2763 22.2761C14.0163 22.5361 13.675 22.6667 13.3337 22.6667C12.9923 22.6667 12.651 22.5361 12.391 22.2761L8.39099 18.2761C7.86966 17.7547 7.86966 16.9121 8.39099 16.3907C8.91233 15.8694 9.75499 15.8694 10.2763 16.3907L13.3337 19.4481L21.7243 11.0574C22.2457 10.5361 23.0883 10.5361 23.6097 11.0574C24.131 11.5787 24.131 12.4214 23.6097 12.9427Z" fill="black"/>
                    </svg>

                  <div>
                  
          </div>
                </div>
                ) : (
                     <div onClick={() => {
                        window.scrollTo(0, document.documentElement.scrollHeight);
                        setCurPaymentType(eachType);
                        setRemainingTime('');
                        setQrData('')
                        console.log('i am parent')
                        const payment = ranges?.filter((each) => each?.paymentList?.type?.type === eachType);
                        console.log('payment', payment)
                        console.log('paymentType', eachType)
                        // setCurSelectedPaymentList(curSelectedPaymentList == item?.paymentList?.name ? '' : item?.paymentList?.name);
                          setSelectedPaymentList(payment[0]);
                          setActive('1')
                      }}
                  key={eachType}
                  style={{cursor: 'pointer', borderRadius: '12px',
                  border: '1px solid var(--opacity-black-30, rgba(0, 0, 0, 0.30))'}}
                  // className={`border text-start col-12 p-3 px-2 paymentParentBox rounded shadow mb-3 ${curPaymentType == eachType && "activePaymentType"}`}>
                  className={` ${index === types.length - 1 ? 'mb-0' : 'mb-3'} text-start col-12 py-4 px-3 rounded`}>
                    <div className='d-flex justify-content-between'>
                    <h5 style={{
                      fontFamily: 'Nunito Sans',
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '32px',
                    }} >{eachType}</h5>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path d="M10.8263 12.3866L15.9996 17.5599L21.173 12.3866C21.693 11.8666 22.533 11.8666 23.053 12.3866C23.573 12.9066 23.573 13.7466 23.053 14.2666L16.933 20.3866C16.413 20.9066 15.573 20.9066 15.053 20.3866L8.93297 14.2666C8.41297 13.7466 8.41297 12.9066 8.93297 12.3866C9.45297 11.8799 10.3063 11.8666 10.8263 12.3866Z" fill="black" fill-opacity="0.6"/>
                    </svg>
                   
                    </div>
                  <div>
                      {payment.map((item, index) => (
                     <img className='me-2' style={{ width: '32px', height: '32px', objectFit: 'contain', borderRadius: '5px' }} 
                          src={`${item?.paymentList?.logo ? `https://cbs-payment.s3.ap-southeast-1.amazonaws.com/payment/logo/${item?.paymentList?.logo}` : 'https://play-lh.googleusercontent.com/cnKJYzzHFAE5ZRepCsGVhv7ZnoDfK8Wu5z6lMefeT-45fTNfUblK_gF3JyW5VZsjFc4'}`} alt="" />
                    
                    ))}
                  </div>
                    
                </div>
                )}</>
              );
            }
          })}

         
       {curSelectedPaymentList && (
              <>
                
                          
                          <div className='text-center'>
                            {clickComfirm ? (
                              <Button  className='mt-4 mb-3 btnstyle col-12' style={{width: '100%'}}><Spinner style={{width: '25px', height: '25px'}} /></Button>
                            ) : (
                              <>
                              {curSelectedPaymentList == "AYA Pay" && (
                                active == '2' ? 
                                <Button onClick={getQRData} className='mt-4 mb-3 btnstyle col-12' >{!changeLanguage && "Pay with "}{curSelectedPaymentList?.split(' ')[0]} QR {changeLanguage && data?.paywith}</Button>
                                
                                : 
                                <Button onClick={comfirmPaymentList} className='mt-4 mb-3 btnstyle col-12'>{!changeLanguage && "Pay with "}{curSelectedPaymentList} {changeLanguage && data?.paywith}</Button>
                                
                              )} 
                              {curSelectedPaymentList !== "AYA Pay" && (
                                active == '2' ? 
                                <Button onClick={getQRData} className='mt-4 mb-3 btnstyle col-12'>{!changeLanguage && "Pay with "}{curSelectedPaymentList?.split(' ')[0]} QR {changeLanguage && data?.paywith}</Button>
                                
                                : (
                                  curSelectedPaymentList == "KBZ PWA" ? (
                                    // <Button onClick={PaywithKBZINAPP} className='mt-4 mb-3 btnstyle col-12'>Pay With KBZ Pay</Button>
                                    <MobileView>
                                        <Button onClick={PaywithKBZINAPP} className='mt-4 mb-3 btnstyle col-12'>{!changeLanguage && "Pay with "}KBZ Pay {changeLanguage && data?.paywith}</Button>
                                        {debuggingKBZInappURL &&  <a href={debuggingKBZInappURL}>{debuggingKBZInappURL}</a>}
                                       
                               </MobileView>

                                  ) : (
                                    curPaymentType !== 'Global Card' ? 
                                    (curSelectedPaymentList ? (
                                      // <Button className='mt-4 mb-3 btnstyle col-12'>Pay With MPU pay</Button>
                                      <form
                                      id="paymentForm"
                                      method="post"
                                      // action="https://www.mpuecomuat.com/UAT/Payment/Payment/pay"
                                      action={mpuUrl}
                                      // onSubmit={handleSubmits}
                                    >
                                      <div className="input-container" style={{visibility: 'hidden', width: '0', height: '0'}}>
                                        <input type="text" id="merchantID" name="merchantID" value={mpuBody?.merchantID} />
                                      </div>
                                      <div className="input-container" style={{visibility: 'hidden', width: '0', height: '0'}}>
                                        <input type="text" id="invoiceNo" name="invoiceNo" value={mpuBody?.invoiceNo} />
                                      </div>
                                      <div className="input-container" style={{visibility: 'hidden', width: '0', height: '0'}}>
                                        <input type="text" id="productDesc" name="productDesc" value={mpuBody?.productDesc} />
                                      </div>
                                      <div className="input-container" style={{visibility: 'hidden', width: '0', height: '0'}}>
                                        <input type="text" id="amount" name="amount" value={mpuBody?.amount} />
                                      </div>
                                      <div className="input-container" style={{visibility: 'hidden', width: '0', height: '0'}}>
                                        <input type="text" id="currencyCode" name="currencyCode" value={mpuBody?.currencyCode} />
                                      </div>
                                      <div className="input-container" style={{visibility: 'hidden', width: '0', height: '0'}}>
                                        <input type="text" id="userDefined1" name="userDefined1" value={mpuBody?.userDefined1} />
                                      </div>
                                      <div className="input-container" style={{visibility: 'hidden', width: '0', height: '0'}}>
                                        <input type="text" id="FrontendURL" name="FrontendURL" value={mpuBody?.FrontendURL} />
                                      </div>
                                      <div className="input-container" style={{visibility: 'hidden', width: '0', height: '0'}}>
                                        <input
                                          type="text"
                                          id="hashValue"
                                          name="hashValue"
                                          value={mpuBody?.hashValue}
                                        />
                                      </div>
                                      {/* <input type="submit" value="Submit Payment" /> */}
                                      <Button id='mpusubmit' type="submit" style={{visibility: 'hidden', width: '0', height: '0'}}
                                       className='mt-4 mb-3 btnstyle col-12'>{!changeLanguage && "Pay with "} {curSelectedPaymentList} {changeLanguage && data?.paywith}</Button>
                                      <Button onClick={mpuSubmit} className=' btnstyle col-12'>{mpucomfirm ?  <Spinner style={{width: '25px', height: '25px'}} />  : `${!changeLanguage ? "Pay with " : ''} ${curSelectedPaymentList} ${changeLanguage ? data?.paywith : ''}`} </Button>
                                         
                                    </form>
                                    )
                                    :                                     
                                    <Button className='mt-4 mb-3 btnstyle col-12'>{!changeLanguage && "Pay with "} {curSelectedPaymentList} {changeLanguage && data?.paywith}</Button>
                                  ) : (
                                    <Button onClick={GCComfirm} className='mt-4 mb-3 btnstyle col-12'>{!changeLanguage && "Pay with "} Global Card {changeLanguage && data?.paywith}</Button>
                                  ))
                                )
                                
                              )}
                            </>
                            )}
                            <br/>
                            {/* <input type="checkbox" /> */}
                            <span className='ps-1' style={{fontSize: '.8rem'}}>By processing the payment, you are confirming that you agree to our Terms and Conditions and </span><br />
                            <a  className='ps-1' style={{fontSize: '.8rem'}} href="#">Privacy Policy</a>.
                          
                          <h6 style={{
                            color: "#000",
                            fontFamily: "Nunito Sans",
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 300,
                            lineHeight: 'normal',
                          }} className='pt-3'>Powered by <a style={{textDecoration: 'none'}} href="https://ethicaldigit.com/">Connected Business Solution</a></h6>
                          </div>
              </>
                        
          )}
        </div>
       
      </div>
      
      <Modal isOpen={successModalOpen} className='modal-dialog-centered'>
        
        <ModalHeader toggle={() => {setSuccessModalOpen(false);setTimeOutTrue(false);setRemainingTime('');setRemainingTime('');}}>{timeoutTrue ? "Payment Timeout" : "Complete Payment"}</ModalHeader>
      
        <ModalBody>
          {!success && (
          <>
          <p>{successMsg}</p>
          {successMsg && (
             <div className='text-center'>
            <img  style={{width: '162px'}} src="https://pay.ethicaldigit.com/two.gif" alt="" />
          </div>
          )}
         
          </>)}
          {timeoutTrue && (
             <div className='text-center'>
            <img  style={{width: '162px'}} src="https://pay.ethicaldigit.com/hand-signal.gif" alt="" />
          </div>
          )}
          {timeoutTrue && <div className='text-center'><p>Your transaction payment is timeout. Please try again. </p></div>}
          {success && (
            <div class="outer">
            <div class="middle">
             <div class="loading-circle">
              <div class="circle1 rotate-right"></div>
              <div class="circle2 rotate-left"></div>
              <div class="percent" id="percent">Success!</div>
             </div>
            </div>
           </div>
          )}
          {remainingTime && <div className='text-center pt-2'><p>{!timeoutTrue && `Time Left: ${remainingTime}`}</p></div>}

          
        </ModalBody>
        {!success && (
                <>
                  {timeoutTrue && (
        <ModalFooter>
           
                    <div>
                      <Button onClick={() => {
                        setSuccessModalOpen(false)
                         setTimeOutTrue(false)
                         setRemainingTime('')
                         comfirmPaymentList()
                      }} className='me-2'>Retry</Button>
                      <Button onClick={() => retryOnce()}>Cancel</Button>
                    </div>
        </ModalFooter>
                  )}
                 
                </>
                  )}
      </Modal>


      <Modal isOpen={tokenExpire} className='modal-dialog-centered'>
        <ModalBody className='text-center'>
            <div className='text-center'>
            <img  style={{width: '162px'}} src="https://pay.ethicaldigit.com/fail.gif" alt="" />
            <p className='mt-2'>{reason}</p>
          </div>
        <Button onClick={() => redirect()} className='m-auto btnstyle mb-2'>Back to Home</Button>
        </ModalBody>
      </Modal>

      <Modal isOpen={qrData ? true : false} className='modal-dialog-centered'>
      <ModalHeader toggle={() => {
        setQrData('')
        setRemainingTime('');
        setTimeOut('')
        setTimeOutTrue(false)}}>{timeoutTrue ? data?.paymentTimeout : data?.completePayment}</ModalHeader>

        <ModalBody className='text-center'>
            <div className='text-center'>
            {qrData && qrData !== 'refreshing' ? 
            <div className='qrdiv' style={{position: 'relative'}}><QRCode value={qrData} />{remainingTime == 'Time out' && <span className='refreshCcw' onClick={() => {
                            setQrData('refreshing');
                            setRemainingTime('');
                            fetch(`https://payment-backend.ethicaldigit.com/checkout/choosePayment`, {
                              method: "POST",
                              headers: {
                                "Content-Type": 'Application/JSON',
                                Authorization: `Bearer ${token}`
                              },
                              body: JSON.stringify({paymentListID: curQrId})
                          })
                              .then((response) => response.json())
                              .then(json => {
                                console.log(json)
                                if(json?.success) {
                                  if(json?.token) {
                                    var currentUrl = window.location.href;
                                    var url = new URL(currentUrl);
                                    url.searchParams.set('token', json?.token);
                                    window.history.replaceState({}, '', url.toString());
                                  }
                                  setQrData(json?.qrdata)
                                  console.log('QR response', json)
                                  setTimeOut(json?.expiredAt)
  
                                }
                                if(json?.err == 3400) {
                                  if(json?.success) {
                                    // window.location.href = '/success';
                                    navigate('/success');

                                  } else {
                                    const socket = io("https://payment-backend.ethicaldigit.com");
                  
                                  socket.emit("checkoutTimeout", {"transactionID": decodedData?.transactionID, 'reason': 'token'});
                  
                                  socket.on("frontendURL", (data) => {
                                    
                                    if(data.err == 3400) {
                                      setReason(data?.msg)
                                      setTokenExpire(true)
                                      setRedirectURL(data?.url)
                                    }
                                    
                                  })
                                  }
                                }
                              })
                              .catch(e => console.log(e))
                          }}>
                            <RefreshCcw style={{color: 'white', width: '50px', height: '50px', marginTop: '110px',  cursor: 'pointer'}} /></span>
                            }</div> : 
                          <div class="wrapperxl m-auto">
                                  <div class="profilePicxl animate din">      </div>
                              </div>}
                          {remainingTime && <p className='pt-2 m-0'>{data?.remainingTime}: {remainingTime}</p>}
            {/* <p className='mt-2'>qr</p> */}
          </div>
        {/* <Button onClick={() => redirect()} className='m-auto btnstyle mb-2'>Back to Home</Button> */}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Main;
